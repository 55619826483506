import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <p className="footer-text">All Rights Reserved. © 2024 AEG Consultancy Services Pvt Ltd | Designed By: Gayatri Harinkhede</p>
    </div>
  );
};

export default Footer;
