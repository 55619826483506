import React from 'react';
import about from './image/About.jpg';
import './About.css';
import { Link } from 'react-router-dom';
import aeg from './image/AEG.jpg';

const About = () => {
  return (
    <div className='container'>
      <div className='about-page'>
        <h1 className='Head'>About US</h1>

        <div className='row-content'>
          {/* Left Section */}
          <div className='left-section'>
            <p>
              At AEG Consultancy Services Pvt Ltd, we believe that research is the cornerstone of innovation and progress. Our mission is to empower researchers by offering an all-in-one platform that integrates consultancy, equipment supply, proposal writing, and international collaboration opportunities.

              In a world where research drives change, we understand the challenges that researchers face in accessing the right resources, tools, and support. That’s why we are committed to providing solutions that simplify the research process. 
             

                Thank you for trusting us to be a part of your research journey.
              </p>

            
            <p>
              AEG Consultancy Services Pvt Ltd
            </p>
           <Link to='/ContactForm'> <button className='about-btn-c'>Contact Us</button></Link>
          </div>

          {/* Right Section */}
          <div className='right-section'>

            <img src={aeg} alt="" />

          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
