import React from 'react';
import './App.css'
import Hero from './Components/Hero'
import Slider from './Components/Slider';
// import Home from './Components/Home ';
import Notice from './Components/Notice';
import About from './Components/About';
// import Achievements from './Components/Achivements';
import ContactForm from './Components/Contact';
// import ConferencePage from './Components/ConferencePage';
import Services from "./Components/Services";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Conference from './Components/Conference';
import Home from './Components/Home';
import Products from './Components/Products';
import Workshop from './Components/Workshop';
import Register from './Components/Register';
// import Achievements from './Components/Achivements';
import Achievements from './Components/Scroll/Achivements';
import RegisterConference from './Components/RegisterConference';
import Footer from './Components/Footer';
// import Ach from './Components/Ach';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Achivements" element={<Achievements/>} />
        {/* <Route path="/Ach" element={<} /> */}

        <Route path="/ContactForm" element={<ContactForm />} />
        <Route path="/Conference" element={<Conference />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="/register" element={<Register />} />
        <Route path="/registerConference" element={<RegisterConference />} /> 
      </Routes>
    </Router>






  )
}

export default App;
